import styled, { css } from "styled-components";
interface TypeProps {
  student?: boolean;
  teacher?: boolean;
  edu?: boolean;
  type?: string;
}
export const GraphSection = styled.div`
  border-bottom: 1px solid #000000;
`;

export const SurveyTerm = styled.div`
  border-top: 1px solid #000000;
  height: 84px;
  width: 100%;
  display: flex;
  background: #eeeeee;
  border-bottom: 1px solid #d2d2d2;
  align-items: center;

  & > div:nth-child(1) {
    margin-left: 30px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #1c2434;
  }
  & > div:nth-child(2) {
    margin-left: 40px;
    box-sizing: border-box;
    width: 397px;
    height: 50px;

    background: #ffffff;
    border: 1px solid #d2d2d2;
    place-content: center;
    text-align: center;

    & > div {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 24px;
      color: #717171;
    }
  }
`;
export const SurveyGraph = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
  display: flex;
  gap: 3px;
`;

export const TypeDiv = styled.div<TypeProps>`
  width: 33%;
  padding: 5px;
  margin-bottom: 40px;
  & > .percent {
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-bottom: 10px;
    & > div:nth-child(1) {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      /* identical to box height */
      display: flex;
      align-items: center;

      color: #3b3d53;
    }
    & > div:nth-child(2) {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;

      color: #84869a;
    }
  }

  & > .bar {
    height: 32px;
    background: #eeeeee;
    border: 1px solid #acacac;
    border-radius: 999px;
    position: relative;
    & > .bar-color {
      /* Progress */

      position: absolute;
      height: 32px;
      border-radius: 999px;
      background: ${({ type }) =>
        type === "student"
          ? "#a588ff"
          : type === "teacher"
          ? "#cdff44"
          : "#ffce51"};

      /*${({ student }) =>
        student &&
        css`
          background: #a588ff;
        `}
      ${({ teacher }) =>
        teacher &&
        css`
          background: #cdff44;
        `}
      ${({ edu }) =>
        edu &&
        css`
          background: #ffce51;
        `}*/
    }
  }

  & > .subject {
    margin-top: 20px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    display: flex;
    align-items: flex-end;

    color: #3b3d53;
  }
`;

export const BarDiv = styled.div<{ percent: number }>`
  width: ${({ percent }) => `${percent}%`};
`;
