import styled from "styled-components";

export const BoardViewSection = styled.section`
  min-width: 1400px;
  height: 100%;
  margin: auto;
`;

export const ViewTitle = styled.div`
  margin-top: 60px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .title-img {
    margin-right: 10px;
  }
  & > .title-txt {
    margin-left: 10px;

    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */
    display: flex;
    align-items: flex-end;
    text-align: center;

    color: #000000;
  }
`;
export const ViewHeaderImg = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;
