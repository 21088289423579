import styled from "styled-components";

export const BoardSection = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
`;

export const NoticeDiv = styled.div`
  width: 698px;
  & > .title {
    display: flex;
    justify-content: space-between;

    align-items: baseline;
    margin-bottom: 30px;

    & > div:first-child {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 43px;
      /* identical to box height */
      letter-spacing: 0.1px;

      color: #000000;
    }
    & > div:last-child {
      display: flex;

      font-family: "Pretendard";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
      align-self: baseline;
      cursor: pointer;
      & > div:first-child {
        align-content: center;
      }
    }
  }

  & > .table {
    & > table {
      width: 100%;
      border-collapse: collapse;
      border-top: 2px solid #000000;
      border-bottom: 2px solid #000000;
      & tr {
        border-bottom: 1px solid #b8b8b8;

        & td {
          padding-left: 20px;

          font-family: "Pretendard";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 59px;
          /* or 295% */
          letter-spacing: -0.4px;

          color: #333333;
        }
      }
    }
  }
`;

export const ContactDiv = styled.div`
  width: 640px;
  & > .title {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    /* identical to box height */
    letter-spacing: 0.1px;

    color: #000000;

    margin-bottom: 30px;
  }
`;

export const Form = styled.div`
  & .address {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    & > div:first-child {
      width: 20%;
    }
    & > div:nth-child(2) {
      width: 30%;
    }
    & > div:last-child {
      width: 50%;
    }
  }

  & .content {
    width: 100%;
    & > textarea {
      width: 95%;
      resize: none;
      margin-top: 10px;
      border-radius: 8px;
      border: 1px solid #b8b8b8;
      height: 200px;
      padding: 15px;

      font-family: "Pretendard";
      font-style: normal;
      font-size: 18px;
      line-height: 16px;
      color: #262626;
    }
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
  }
`;
