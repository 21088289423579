import React from "react";
import * as S from "./styles/Footer.styled";

export const Footer: React.FC = () => {
  return (
    <S.FooterSection>
      <S.FooterString>
        <div>2024년 학교 환경교육 현황조사 문의</div>
        <div>
          <div>이메일 | see-survey@surveypp.com</div>
          <div>전화 | 02-2055-1029</div>
        </div>
      </S.FooterString>
    </S.FooterSection>
  );
};
