export const FaqData = [
  {
    seq: 1,
    title: "현재 업무로도 너무 바쁜데 조사를 꼭 진행해야 하나요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "교육부에서는 환경교육법 제10조2(학교환경교육의 실시)에 따른 환경교육 의무화 시행 등 국가 정책이 시행됨에 따라 학교 환경교육의 인식, 학습효과 등 학교 환경교육 현황을 파악하고 이후 학교 환경교육이 더 발전할 수 있는 대안을 마련하기 위해 본 조사를 시행하고 있습니다. 학교 환경교육의 발전을 위해 귀교의 협조를 부탁드립니다.",
    secondLine: null,
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 2,
    title: "<환경교육 실태조사>와 다른 조사인가요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "본 조사는 환경부 주관의 <환경교육 실태조사>와 조사 내용과 목적이 다른 별도의 조사입니다. 교육부 주관 <학교 환경교육 현황조사>인 본 조사는 학교 구성원(학생, 교원)의 인식 및 경험에 관한 개인 수준 조사에 중점을 두고 있습니다.",
    secondLine: null,
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 3,
    title: "조사 공문을 못 받았는데, 어디에서 확인해야 하나요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "조사 공문은 본 플랫폼 초기화면에서 확인하실 수 있습니다. 관련하여 확인이 불가능하실 경우, ‘Contact Us(이메일)’ 혹은 학교 환경교육 현황조사 ‘콜센터(전화)’로 문의 부탁드립니다.",
    secondLine: null,
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 4,
    title: "학생들을 꼭 한 장소에 모아두고 PC로 진행해야 하나요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "학교 여건에 따라 PC 또는 모바일로 참여 가능합니다. 다만, 안정적인 표본 수 확보 및 데이터 신뢰도 제고를 위하여 한 장소에 모아두고 담임교사의 관리 하에 진행 부탁드립니다.",
    secondLine: null,
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 5,
    title: "조사에 시간이 어느정도 소요될까요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "학생 및 일반 교사는 약 10~15분, 환경교육 담당교사는 약 15~20분 소요됩니다.",
    secondLine: null,
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 6,
    title: "조사는 언제 진행해야 하나요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "조사 기간은 10월 14일(월) ~ 11월 8일(금) 입니다. 이 기간 내에 각 학교 사정(일정)에 맞추어 자유롭게 진행하시면 됩니다.",
    secondLine: null,
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 7,
    title:
      "설문조사에 참여하다가 조사 링크 창을 꺼버렸어요. 어떻게 해야 하나요?",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "학생용 및 일반교원용 설문의 경우, 조사 중간에 창을 끌 경우, 처음부터 다시 응답해야 합니다. 설문조사 링크는 제한 없이 여러 번 접속 가능하오니 다시 참여해주시기 바랍니다.",
    secondLine:
      "단, 환경교육 담당교원용 설문은 1회만 참여할 수 있으며, 창을 끄더라도 중단한 곳부터 시작할 수 있습니다.",
    imgCnt: 0,
    imgType: "jpg",
    test: true,
  },
];
