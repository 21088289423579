import React from "react";
import * as S from "./styles/AboutSurveySection.styled";

export const SurveyPartSection: React.FC = () => {
  return (
    <S.AboutSurveySection>
      <S.AboutTitle>조사대상</S.AboutTitle>
      <S.AboutContent>
        <S.AboutSecond>
          <div className={"header"}>
            <div>학생</div>
            <div>교장(감) 및 교사</div>
            <div>환경교육 담당교사</div>
          </div>
          <div className={"body"}>
            <div>
              <div className={"dot"}>
                <div>·</div>
                <div>학년별 각 1개 학급 진행</div>
              </div>
              <div>
                <div></div>
                <div>
                  <div>-초등학교: </div>
                  <div>5~6학년</div>
                </div>
              </div>
              <div>
                <div></div>
                <div>
                  <div>-중 학 교 : </div>
                  <div>1~2학년</div>
                </div>
              </div>
              <div>
                <div></div>
                <div>
                  <div>-고등학교: </div>
                  <div>1~2학년</div>
                </div>
              </div>
            </div>
            <div>
              <div className={"dot"}>
                <div>·</div>
                <div>조사 대상 학교장 혹은 교감 1명</div>
              </div>
              <div style={{ display: "block" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ width: "20px" }}>·</div>
                  <div>일반 교사 18명</div>
                </div>
                <div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ color: "#6f6f6f" }}>
                    조사 참여 학급 담임교사 2명 포함
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <div>·</div>
                <div style={{ marginBottom: "0px" }}>환경교육 담당교사 1명</div>
              </div>
              <div>
                <div style={{ width: "20px" }}></div>
                <div style={{ color: "#6f6f6f" }}>없을 시 교장(감)이 진행</div>
              </div>
            </div>
          </div>
        </S.AboutSecond>
      </S.AboutContent>
    </S.AboutSurveySection>
  );
};
