import React, { useState } from "react";
import * as S from "./styles/LinkSection.styled";
import {
  asset11,
  eduImg,
  line150,
  studentImg,
  teacherImg,
} from "../../styles/image/svg";
import { Option } from "../common/drop-down/DropDown";
import Button from "../common/button/Button";
import { Modal } from "./ModalComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { PeriodModal } from "./PeriodModal";

export const LinkSection: React.FC = () => {
  const [studentMenuOpen, setStudentMenuOpen] = useState(false);
  const [teacherMenuOpen, setTeacherMenuOpen] = useState(false);
  const [eduMenuOpen, setEduMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const userData = useSelector((state: RootState) => state.user);

  const linkOptions: Option[] = [
    { value: "join", label: "조사 참여하기" },
    { value: "copy", label: "조사링크 복사하기" },
    {
      value: "qr",

      label: "조사링크 QR코드 보기",
    },
    {
      value: "manual",

      label: "응답매뉴얼 다운로드",
    },
    { value: "survey", label: "설문지 다운로드" },
  ];
  const clickMenu = (type: string) => {
    if (type === "student") {
      setStudentMenuOpen(!studentMenuOpen);
      setTeacherMenuOpen(false);
      setEduMenuOpen(false);
    } else if (type === "teacher") {
      setStudentMenuOpen(false);
      setTeacherMenuOpen(!teacherMenuOpen);
      setEduMenuOpen(false);
    } else if (type === "edu") {
      setStudentMenuOpen(false);
      setTeacherMenuOpen(false);
      setEduMenuOpen(!eduMenuOpen);
    }
  };

  const buttonClick = (value: string, type: string) => {
    const today = new Date();
    const startDay = new Date(2024, 9, 14, 0, 0, 0);

    switch (value) {
      case "join":
        if (today < startDay) {
          periodModalOpen();
        } else {
          join(type);
        }
        break;
      case "copy":
        if (today < startDay) {
          periodModalOpen();
        } else {
          copy(type);
        }
        break;
      case "qr":
        if (today < startDay) {
          periodModalOpen();
        } else {
          qr(type);
        }
        break;
      case "manual":
        manual(type);
        break;
      case "survey":
        survey(type);
        break;
      default:
        break;
    }
  };

  const join = (type: string) => {
    let url = "";
    if (studentMenuOpen) {
      url = userData.studentUrl ? userData.studentUrl : "";
    } else if (teacherMenuOpen) {
      url = userData.teacherUrl ? userData.teacherUrl : "";
    } else if (eduMenuOpen) {
      url = userData.ecoUrl ? userData.ecoUrl : "";
    }
    window.open(url);
  };

  const copy = (type: string) => {
    let url = "";
    if (studentMenuOpen) {
      url = userData.studentUrl ? userData.studentUrl : "";
    } else if (teacherMenuOpen) {
      url = userData.teacherUrl ? userData.teacherUrl : "";
    } else if (eduMenuOpen) {
      url = userData.ecoUrl ? userData.ecoUrl : "";
    }
    navigator.clipboard.writeText(url);
    toast.success("조사링크가 복사되었습니다.", {
      position: "top-right",
      autoClose: 3000, // 3초 후 자동으로 닫힘
    });
  };

  //큐알 보기
  const qr = (type: string) => {
    let url = "";
    if (studentMenuOpen) {
      url = userData.studentQr ? userData.studentQr : "";
    } else if (teacherMenuOpen) {
      url = userData.teacherQr ? userData.teacherQr : "";
    } else if (eduMenuOpen) {
      url = userData.eduQr ? userData.eduQr : "";
    }
    setQrUrl(url);
    setModalType(type);
    openModal();
  };

  //매뉴얼 다운로드
  const manual = (type: string) => {
    let fileTitle = `설문링크 응답매뉴얼_${
      type === "student"
        ? "학생용"
        : type === "teacher"
        ? "일반교사용"
        : "환경교육 담당자용"
    }`;

    const filePath = `/download/manual/${type}.pdf`;
    // a 태그를 생성하여 다운로드 실행
    const link = document.createElement("a");
    link.href = filePath;
    link.download = `${fileTitle}.pdf`; // 다운로드할 파일명
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //설문 다운로드
  const survey = (type: string) => {
    let fileTitle = `설문지_${
      type === "student"
        ? "학생용"
        : type === "teacher"
        ? "일반교사용"
        : "환경교육 담당자용"
    }`;

    const filePath = `/download/survey/${type}.pdf`;
    // a 태그를 생성하여 다운로드 실행
    const link = document.createElement("a");
    link.href = filePath;
    link.download = `${fileTitle}.pdf`; // 다운로드할 파일명
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //공문 다운로드
  const download = () => {
    /* const regionMap: { [key: string]: string } = {
      경기: "Gyeonggi",
    };*/
    const regionList = [
      "경기",
      "광주",
      "인천",
      "전북",
      "제주",
      "대전",
      "세종",
      "충북",
      "강원",
      "경북",
      "대구",
      "부산",
      "전남",
      "충남",
      "경남",
      "서울",
    ];

    const region = userData?.region;
    if (region && regionList.includes(region)) {
      const fileName = region;
      const filePath = `/download/document/${fileName}.pdf`;
      // a 태그를 생성하여 다운로드 실행
      const link = document.createElement("a");
      link.href = filePath;
      link.download = `${region}_공문.pdf`; // 다운로드할 파일명
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderOptions = (surveytype: string) => {
    const rows = [];
    for (let i = 0; i < linkOptions.length; i++) {
      const { value, label } = linkOptions[i];
      const row = (
        <Button
          key={`${i}_button`}
          style={{ width: "100%" }}
          optionstype={"true"}
          surveytype={surveytype}
          onClick={() => buttonClick(value, surveytype)}
        >
          {label}
        </Button>
      );
      rows.push(row);
    }
    return rows;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const periodModalOpen = () => {
    setIsPeriodModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsPeriodModalOpen(false);
  };

  return (
    <S.LinkSection>
      <S.SectionTitle>
        <div>조사 링크 및 조사자료 확인</div>
        <div>* 버튼을 누르고 필요한 기능을 선택하세요</div>
      </S.SectionTitle>
      <S.SectionDiv>
        <S.LinkDiv type={"student"}>
          <div className={"subject"}>학생용</div>
          <div className={"img"}>
            <img src={studentImg} alt={"student_img"} />
          </div>
          <div className={"drop-down"}>
            <div
              className={"place-holder"}
              onClick={() => clickMenu("student")}
            >
              <div>학생용 링크</div>
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            {studentMenuOpen ? (
              <div className={"options"}>{renderOptions("student")}</div>
            ) : (
              ""
            )}
          </div>
        </S.LinkDiv>
        <S.LinkDiv type={"teacher"}>
          <div className={"subject"}>일반교사용</div>
          <div className={"img"}>
            <img src={teacherImg} alt={"teacher_img"} />
          </div>
          <div className={"drop-down"}>
            <div
              className={"place-holder"}
              onClick={() => clickMenu("teacher")}
            >
              <div>일반교사용 링크</div>
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            {teacherMenuOpen ? (
              <div className={"options"}>{renderOptions("teacher")}</div>
            ) : (
              ""
            )}
          </div>
        </S.LinkDiv>
        <S.LinkDiv type={"edu"}>
          <div className={"subject"}>환경교육 담당자용</div>
          <div className={"img"}>
            <img src={eduImg} alt={"edu_img"} />
          </div>
          <div className={"drop-down"}>
            <div className={"place-holder"} onClick={() => clickMenu("edu")}>
              <div>환경교육 담당자 링크</div>
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            {eduMenuOpen ? (
              <div className={"options"}>{renderOptions("edu")}</div>
            ) : (
              ""
            )}
          </div>
        </S.LinkDiv>
        <Modal
          isOpen={isModalOpen}
          qrUrl={qrUrl}
          onClose={closeModal}
          type={modalType}
        />
        <PeriodModal isOpen={isPeriodModalOpen} onClose={closeModal} />
        <S.VerticalLine>
          <img src={line150} alt={"line"} />
        </S.VerticalLine>
        <S.LinkDiv type={"download"}>
          <div className={"subject"}></div>
          <div className={"img"}>
            <img src={asset11} alt={"asset"} />
          </div>
          <div className={"drop-down"}>
            <Button buttontype={"link-download"} onClick={download}>
              공문 다운로드
            </Button>
          </div>
        </S.LinkDiv>
      </S.SectionDiv>
    </S.LinkSection>
  );
};
