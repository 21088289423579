import styled from "styled-components";

export const NoticeTable = styled.div`
  width: 1400px;
  margin: auto;
  margin-bottom: 100px;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & > thead {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #d2d2d2;
    background: #eeeeee;
    & th {
      padding: 20px 0px 20px 0px;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      /* identical to box height */
      align-items: center;

      color: #000000;
    }
  }
  & > tbody {
    border-bottom: 1px solid #000000;
    & tr {
      text-align: center;
      border-bottom: 1px solid #d2d2d2;

      font-family: "Pretendard";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 69px;
      /* or 288% */

      color: #6f6f6f;
      & td:nth-child(2) {
        padding-left: 40px;
        text-align: left;
      }
      & .title {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      & > .faq-content-td {
        text-align: left;
        padding: 30px 120px;
        & > div {
          line-height: 43px;
        }
        & > div:nth-child(2) {
          margin-top: 20px;
        }
      }
      & > .notice-content-td {
        text-align: left;
        padding: 30px 70px;
        line-height: 43px;
        & .red-txt {
          color: darkred;
        }
        & u {
          color: #007aff;
        }
      }
    }
    & tr:last-child {
      border-bottom: none;
    }
  }
`;

export const NoticeListButton = styled.div`
  margin-top: 30px;
  margin-left: 30px;
  width: 77px;
  display: block;

  font-family: Pretendard;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  color: #787c82;

  cursor: pointer;
`;
