export const NoticeData = [
  {
    seq: 1,
    title: "[공지] 조사 참여 협조 안내",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "안녕하십니까.<br/>" +
      "교육부/한국환경보전원의 의뢰를 받아 <2024년 학교 환경교육 현황조사>를 실시하고 있는 ㈜서베이피플입니다.<br/>" +
      "<br/>" +
      "본 조사는 학교 환경교육의 인식, 학습효과 등 학교 환경교육 현황을 파악하고, <br/>" +
      "학교 환경교육을 더 발전시킬 수 있는 대안을 마련하기 위해 시행하고 있습니다. <br/>" +
      "학교 환경교육의 발전을 위해 귀교의 적극적인 협조를 부탁드립니다.<br/>" +
      "<br/>" +
      "관련하여 문의사항이 있으신 경우 <2024년 학교 환경교육 현황조사> 콜센터로 연락 부탁드립니다.<br/>" +
      "<br/>" +
      "감사합니다.<br/>",
    imgCnt: 1,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 2,
    title: "[공지] 2024년 학교 현황조사 콜센터 이용 안내",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "★ 2024년 학교 현황조사 콜센터 운영시간 안내<br/>" +
      "ㅤ<b><span class='red-txt'>(방법1)</span> <u>see-survey@surveypp.com</u> <span class='red-txt'>혹은 통합 플랫폼 Contact Us에 문의글 남기기</span></b><br/>" +
      "ㅤㅤ→ 이메일 혹은 Contact Us로 문의사항을 남겨 주시면, 담당 연구원이 순차적으로 확인 후 <br/>" +
      "ㅤㅤㅤ답변해드릴 예정입니다.<br/>" +
      "ㅤ<b><span class='red-txt'>(방법2) 콜센터 연락처(02-2055-1029)로 전화</span></b><br/>" +
      "ㅤㅤ→ 운영시간은 평일(월~금) 10시~18시입니다. <br/>" +
      "ㅤㅤㅤ점심시간(12:00 ~ 13:00)는 전화 연결이 어려울 수 있습니다. <br/>" +
      "ㅤㅤㅤ해당 시간에 이용하실 경우 Contact Us 혹은 이메일로 문의 부탁드립니다.<br/>",
    imgCnt: 1,
    imgType: "jpg",
    test: true,
  },
  {
    seq: 3,
    title: "[공지] 2024년 학교 현황조사 답례품 지급 안내",
    writer: "관리자",
    date: "2024-10-08",
    content:
      "2024년 학교 현황조사에 참여해주시는 모든 분께 <b><span class='red-txt'>조사 종료 후 답례품을 지급</span></b>할 예정입니다.<br/>" +
      "학생용 답례품은 학교로 배송될 예정으로, 각 학급별로 배포해주시면 됩니다.<br/>" +
      "교사의 경우 응답해주신 핸드폰 번호로 조사 종료 이후 일괄 발송될 예정입니다. <br/>" +
      "답례품은 11월 중으로 발송될 예정이니 참고 부탁드립니다.<br/>",
    imgCnt: 1,
    imgType: "jpg",
    test: true,
  },
];
