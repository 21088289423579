import React from "react";
import * as S from "./styles/CurrentView.styled";
import { titleSchool } from "../../../styles/image/svg";
import { GraphSection } from "../../current/GraphSection";
import { LinkSection } from "../../current/LinkSection";
import { BoardSection } from "../../current/BoardSection";
export const CurrentView: React.FC = () => {
  return (
    <S.CurrentViewSection>
      <S.ViewTitle>
        <div className={"title-img"}>
          <img src={titleSchool} alt={"학교"} />
        </div>
        <div className={"title-txt"}>우리 학교 조사 참여 현황</div>
      </S.ViewTitle>
      <GraphSection />
      <LinkSection />
      <BoardSection />
    </S.CurrentViewSection>
  );
};
