import React, { useState } from "react";
import * as S from "./styles/Navigation.styled";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NaviLink = styled(NavLink)`
  text-decoration: none;
`;
const SubNavi = styled(NavLink)`
  text-decoration: none;
  display: block;
  height: 60px;
  text-align: center;
  align-content: center;
`;

export const Navigation: React.FC = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const menuclick = () => {
    setShowSubMenu(!showSubMenu);
  };

  const subMenuClose = () => {
    setShowSubMenu(false);
  };
  return (
    <S.NaviSection>
      <S.NaviContainer>
        <S.NaviTitle>2024년 학교 환경교육 현황조사</S.NaviTitle>
        <S.NaviMenuContainer>
          <NaviLink to="/current">
            <S.NaviMenu>조사 참여 / 관리</S.NaviMenu>
          </NaviLink>
          <NaviLink to="/about">
            <S.NaviMenu>조사 개요</S.NaviMenu>
          </NaviLink>
          <NaviLink to="/dashboard">
            <S.NaviMenu>응답 현황</S.NaviMenu>
          </NaviLink>
          <S.NaviMenu onClick={() => menuclick()}>
            공지사항 / FAQ
            <S.SubMenuDiv
              show={showSubMenu.toString()}
              onMouseLeave={() => subMenuClose()}
            >
              <SubNavi to="/notice">
                <S.NaviSubMenu>공지사항</S.NaviSubMenu>
              </SubNavi>
              <svg
                width="129"
                height="2"
                viewBox="0 0 129 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1H129" stroke="#D9D9D9" />
              </svg>
              <SubNavi to="/faq">
                <S.NaviSubMenu>FAQ</S.NaviSubMenu>
              </SubNavi>
            </S.SubMenuDiv>
          </S.NaviMenu>
        </S.NaviMenuContainer>
      </S.NaviContainer>
    </S.NaviSection>
  );
};
