import axios, { AxiosInstance, AxiosStatic } from "axios";

const BaseUrl =
  process.env.NODE_ENV === "development" ? "http://localhost:3000" : "";

const headers = {
  "Content-Type": "application/json",
};

const instance: AxiosInstance = axios.create({
  baseURL: `${BaseUrl}/api`,
  headers,
  timeout: 20000,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  // const { getCookie } = useCookies();
  // const token = getCookie<string>(CookieKeys.token);
  // if (token) {
  //   config.headers["Authorization"] = `Bearer ${token}`;
  // }
  return config;
});

export const axiosInstance = instance as AxiosStatic;
