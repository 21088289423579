import React from "react";
import * as S from "./styles/NoticeTable.styled";
import { NoticeData } from "../../data/board/notice-data";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Links = styled(Link)`
  text-decoration: none;

  font-family: Pretendard;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  color: #787c82;
  &:hover {
    text-decoration: underline;
  }
`;
export const NoticeRead: React.FC = () => {
  const [searchParams] = useSearchParams();
  const viewNotice = NoticeData.filter(
    (notice) => notice.seq === Number(searchParams.get("seq"))
  )[0];

  //공지사항 헤더 렌더링
  const noticeHeader = () => {
    return (
      <tr key={"headers"}>
        <th style={{ width: "70%" }}>{viewNotice.title}</th>
        <th>{viewNotice.writer}</th>
        <th>{viewNotice.date}</th>
      </tr>
    );
  };
  //공지사항 본문 렌더링
  const noticeContent = () => {
    const rows: any[] = [];

    const row = (
      <tr key={`body_read_notice`}>
        <td colSpan={3} className={"notice-content-td"}>
          <div dangerouslySetInnerHTML={{ __html: viewNotice.content }} />
        </td>
      </tr>
    );
    rows.push(row);
    return rows;
  };

  return (
    <S.NoticeTable>
      <S.Table>
        <thead>{noticeHeader()}</thead>
        <tbody>{noticeContent()}</tbody>
      </S.Table>
      <S.NoticeListButton>
        <Links to={"/notice"}>목록으로</Links>
      </S.NoticeListButton>
    </S.NoticeTable>
  );
};
