import React, { useEffect, useState } from "react";
import * as S from "./styles/BoardTable.styled";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getCurrentData } from "../../apis/survey/survey.api";
export const BoardTable: React.FC = () => {
  const userData = useSelector((state: RootState) => state.user);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const getCurrent = async () => {
      const { ecoTeacher, student, teacher } = await getCurrentData(
        userData.userId ? userData.userId : ""
      );
      setTableData([]);
      let totals: any[] = [
        {
          type: "student",
          level: 1,
          total: 30,
          complete: 0,
        },
        {
          type: "student",
          level: 2,
          total: 30,
          complete: 0,
        },
        {
          type: "teacher",
          level: 1,
          total: 1,
          complete: 0,
        },
        {
          type: "teacher",
          level: 2,
          total: 20,
          complete: 0,
        },
        {
          type: "ecoTeacher",
          level: 1,
          total: 1,
          complete: 0,
        },
      ];
      if (student) {
        student.forEach((v: any) => {
          const { type, complete } = v;
          let levels = 0;
          if (type in ["5학년", "1학년"]) {
            levels = 1;
          } else {
            levels = 2;
          }
          totals.filter(
            (v) => v.type === "student" && v.level === levels
          )[0].complete = complete;
        });
      }
      if (teacher) {
        teacher.forEach((v: any) => {
          const { type, complete } = v;
          let levels = 0;
          if (type === "일반교사") {
            levels = 2;
          } else {
            levels = 1;
          }
          totals.filter(
            (v) => v.type === "teacher" && v.level === levels
          )[0].complete = complete;
        });
      }
      if (ecoTeacher) {
        ecoTeacher.forEach((v: any) => {
          const { complete } = v;
          totals.filter(
            (v) => v.type === "ecoTeacher" && v.level === 1
          )[0].complete = complete;
        });
      }

      setTableData(totals);
    };

    getCurrent();
  }, []);

  const renderingCurrent = () => {
    const rows: any[] = [];
    tableData.forEach((item, index) => {
      const { total, complete } = item;
      const result = complete >= total;
      const row = (
        <S.TD complete={result ? "true" : "false"} key={index}>
          {complete} / {total}
        </S.TD>
      );
      rows.push(row);
    });
    return rows;
  };

  /* const renderingRate = () => {
    const rows: any[] = [];
    tableData.forEach((item, index) => {
      const { total, complete } = item;
      const result = Math.round((complete / total) * 100 * 10) / 10;
      const row = (
        <S.TD complete={result === 100 ? "true" : "false"}>{result} %</S.TD>
      );
      rows.push(row);
    });
    return rows;
  };*/

  return (
    <S.BoardTableSection>
      <S.Table>
        <colgroup>
          <col width={"16.6%"} />
          <col width={"16.6%"} />
          <col width={"16.6%"} />
          <col width={"16.6%"} />
          <col width={"16.6%"} />
          <col width={"16.6%"} />
        </colgroup>
        <thead>
          <tr>
            <th rowSpan={2}>대상자</th>
            <th colSpan={2}>학생</th>
            <th colSpan={3}>교사</th>
          </tr>
          {userData.schoolType === "초등학교" ? (
            <tr>
              <th>5학년</th>
              <th>6학년</th>
              <th>교장/교감</th>
              <th>일반교사</th>
              <th>환경교육 담당교사</th>
            </tr>
          ) : (
            <tr>
              <th>1학년</th>
              <th>2학년</th>
              <th>교장/교감</th>
              <th>일반교사</th>
              <th>환경교육 담당교사</th>
            </tr>
          )}
        </thead>
        <tbody>
          <tr>
            <th>현황</th>
            {renderingCurrent()}
          </tr>

          {/*<tr>
            <th>진행률</th>
            {renderingRate()}
          </tr>*/}
        </tbody>
      </S.Table>
      <S.InfoString>
        <div>
          <div>*</div>
          <div>
            학생 및 교사별 목표 인원은 조사 설계를 바탕으로 모든 학교에 동일하게
            적용되어 있습니다. <br />
            학교 규모에 따라 실제 달성 가능한 인원은 차이가 있을 수 있으니
            참고용으로만 활용 바랍니다.
            {/*<br />
            진행률은 참고용으로만 활용하시기 바랍니다.*/}
          </div>
        </div>
        {/*<div>
          <div></div>
          <div>
            <div>·</div>
            <div>
              학생 수 적용기준 : 2023.10.01. 기준 한국교육개발원 교육통계서비스
              자료 참조(학급당 평균 학생 수 = 학생 수/학급 수)
            </div>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <div>·</div>
            <div>교사 수 적용기준 : 조사설계에 따름</div>
          </div>
        </div>*/}
      </S.InfoString>
    </S.BoardTableSection>
  );
};
