import React from "react";
import * as S from "./styles/PeriodModal.styled";
import Button from "../common/button/Button";

// 모달 컴포넌트
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

export const PeriodModal: React.FC<ModalProps> = ({ isOpen, onClose, id }) => {
  if (!isOpen) {
    return null;
  } // 모달이 닫힌 상태에서는 렌더링되지 않음

  // 모달 창 외부 클릭 시 모달을 닫는 함수
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose(); // 오버레이를 클릭했을 때만 모달을 닫음
    }
  };

  return (
    <S.ModalOverlay onClick={handleOverlayClick}>
      <S.ModalContent>
        <S.StringDiv>
          <div>*조사기간은</div>
          <div>
            <b>2024.10.14.~2024.11.08.</b>입니다.
          </div>
        </S.StringDiv>
        <S.ButtonDiv>
          <Button
            onClick={onClose}
            style={{ width: "100%" }}
            login={"true"}
            disabled={false}
          >
            확인
          </Button>
        </S.ButtonDiv>
      </S.ModalContent>
    </S.ModalOverlay>
  );
};
