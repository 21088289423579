import React, { useEffect, useState } from "react";
import * as S from "./styles/Login.styled";
import LoginImg from "../common/img/login_img.png";
import { Input } from "../common/input/Input";
import Button from "../common/button/Button";
import { loginApi } from "../../apis/user/user.api";
import { UserData } from "../../interfaces/user/user";
import { useDispatch } from "react-redux";
import { setUserData, UserState } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PeriodModal } from "./PeriodModal";

export const Login: React.FC = () => {
  const [id, setID] = useState("");
  const [pw, setPW] = useState("");
  const [loginAccount, setLoginAccount] = useState<UserState | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onKeyPress = (event: any) => {
    if (event.key === "Enter") {
      login();
    }
  };

  //로그인
  const login = async () => {
    try {
      const loginData = {
        userId: id,
        userPw: pw,
      };
      const apiResult: UserData = await loginApi(loginData);
      const {
        _id,
        token,
        userId,
        userSchool,
        studentUrl,
        teacherUrl,
        ecoUrl,
        result,
        schoolType,
        studentQr,
        teacherQr,
        eduQr,
        region,
      } = apiResult;

      if (result) {
        const loginInfo = {
          _id,
          token,
          userId,
          userSchool,
          studentUrl,
          teacherUrl,
          ecoUrl,
          schoolType,
          isLogin: true,
          studentQr,
          teacherQr,
          eduQr,
          region,
        };
        setLoginAccount(loginInfo);
      }
    } catch (e) {
      toast.error("아이디, 비밀번호를 확인해주세요", {
        position: "top-right",
        autoClose: 3000,
      });
      console.log(e);
    }
  };

  const setLoginInfo = () => {
    if (loginAccount) {
      sessionStorage.setItem("userData", JSON.stringify(loginAccount));
      dispatch(setUserData(loginAccount));
      navigate("/current");
    }
  };

  useEffect(() => {
    if (loginAccount) {
      const today = new Date();
      const startDay = new Date(2024, 9, 14, 0, 0, 0);
      if (today < startDay) {
        openModal();
      } else {
        setLoginInfo();
      }
    }
  });

  return (
    <S.LoginSection>
      <S.LoginImgContainer>
        <S.LoginImgTitle>2024년 학교 환경교육 현황조사</S.LoginImgTitle>
        <S.LoginImg>
          <img src={LoginImg} alt={"로그인이미지"} />
        </S.LoginImg>
        <S.LoginImgFooter>
          <div>
            · 조사 참여 및 응답현황 확인을 위해서는 로그인이 필요합니다.
          </div>
        </S.LoginImgFooter>
      </S.LoginImgContainer>
      <S.LoginContainer>
        <S.LoginTitle>Login</S.LoginTitle>
        <S.LoginInputContainer>
          <div>
            <div>ID</div>
            <div>
              <Input
                style={{ width: "100%" }}
                type={"text"}
                inputdesigntype={"login-ID"}
                onKeyPress={(e) => onKeyPress(e)}
                onChange={(e) => setID(e.target.value)}
                value={id}
                placeholder={"아이디를 입력해주세요."}
              />
            </div>
          </div>
          <div>
            <div>Password</div>
            <div>
              <Input
                style={{ width: "100%" }}
                type={"password"}
                inputdesigntype={"login-PW"}
                inputButtonDisabled={pw === ""}
                onKeyPress={(e) => onKeyPress(e)}
                onChange={(e) => setPW(e.target.value)}
                value={pw}
                placeholder={"비밀번호를 입력해주세요."}
              />
            </div>
          </div>
        </S.LoginInputContainer>
        <S.LoginButtonContainer>
          {id !== "" && pw !== "" ? (
            <Button
              style={{ width: "100%" }}
              onClick={() => login()}
              login={"true"}
            >
              Login
            </Button>
          ) : (
            <Button style={{ width: "100%" }} login={"true"} disabled={true}>
              Login
            </Button>
          )}
        </S.LoginButtonContainer>
        <div className={"login-msg"}>
          * 로그인 ID와 초기 비밀번호는 조사 안내 메일 제목의 학교별
          고유코드(3~5자리 숫자) 입니다.
        </div>
      </S.LoginContainer>
      <PeriodModal
        isOpen={isModalOpen}
        onClose={closeModal}
        okayPeriod={setLoginInfo}
      />
    </S.LoginSection>
  );
};
