import React from "react";
import * as S from "./styles/NoticeTable.styled";
import { NoticeData } from "../../data/board/notice-data";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Links = styled(Link)`
  text-decoration: none;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 69px;
  /* or 288% */

  color: #6f6f6f;
  &:hover {
    text-decoration: underline;
  }
`;
export const NoticeTable: React.FC = () => {
  const renderRow = () => {
    const rows: any[] = [];
    NoticeData.sort((a, b) => {
      return b.seq - a.seq;
    }).forEach((notice, index) => {
      const row = (
        <tr key={index}>
          <td>{notice.seq}</td>

          <td className={"title"}>
            <Links to={`/notice-view?seq=${notice.seq}`}>{notice.title}</Links>
          </td>
          <td>{notice.writer}</td>
          <td>{notice.date}</td>
        </tr>
      );
      rows.push(row);
    });
    return rows;
  };
  return (
    <S.NoticeTable>
      <S.Table>
        <colgroup>
          <col width={"7%"} />
          <col width={"60%"} />
          <col width={"15%"} />
          <col width={"20%"} />
        </colgroup>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
          </tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </S.Table>
    </S.NoticeTable>
  );
};
