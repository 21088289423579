import React from "react";
import * as S from "./styles/BoardView.styled";
import { titleSchool } from "../../../styles/image/svg";
import { headerImg } from "../../../styles/image/png";
import { BoardTable } from "../../board/BoardTable";

export const BoardView: React.FC = () => {
  return (
    <S.BoardViewSection>
      <S.ViewTitle>
        <div className={"title-img"}>
          <img src={titleSchool} alt={"학교"} />
        </div>
        <div className={"title-txt"}>응답 현황</div>
      </S.ViewTitle>
      <S.ViewHeaderImg>
        <img src={headerImg} alt={"학교"} />
      </S.ViewHeaderImg>
      <BoardTable />
    </S.BoardViewSection>
  );
};
