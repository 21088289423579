import styled from "styled-components";

export const LoginSection = styled.section`
  max-width: 1400px;
  height: 100%;
  min-height: 650px;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

export const LoginImgContainer = styled.div`
  width: 795px;
  height: fit-content;
  margin: auto 0;
`;
export const LoginContainer = styled.div`
  width: 478px;
  height: fit-content;
  margin: auto 0;

  & > .login-msg {
    margin-top: 30px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;
export const LoginImgTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  align-items: flex-end;
  text-align: center;
  margin-top: 70px;

  color: #00945e;
`;
export const LoginImg = styled.div`
  margin-top: 70px;
`;
export const LoginImgFooter = styled.div`
  /* Rectangle 34624401 */

  width: 100%;
  height: 62px;

  background: #d9d9d9;
  place-content: center;

  & > div {
    margin-left: 50px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #5f5f5f;
  }
`;
export const LoginTitle = styled.div`
  /* Login */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.04em;

  color: #282828;
  margin-bottom: 60px;
`;
export const LoginInputContainer = styled.div`
  width: 100%;
  & > div:nth-child(1) {
    margin-bottom: 30px;
  }
  & > div > div:nth-child(1) {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #383838;
  }
`;
export const LoginButtonContainer = styled.div`
  margin-top: 50px;
`;
