import styled, { css } from "styled-components";
interface ButtonProps {
  login?: string;
  disabled?: boolean;
  optionstype?: string;
  surveytype?: string;
  buttontype?: string;
  contact?: string;
}
export const StyledButton = styled.button<ButtonProps>`
  cursor: pointer;
  /* Login */
  color: #ffffff;
  border: none;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  ${({ login }) =>
    login &&
    css`
      height: 44px;
      background: #00945e;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background: #c7c7c7;
      cursor: not-allowed;
    `}
  ${({ optionstype, surveytype }) =>
    optionstype &&
    css`
      flex-direction: row;
      align-items: center;
      padding: 8px 20px;
      gap: 10px;

      width: 276px;
      height: 50px;

      /* White/White */
      background: #ffffff;
      flex-grow: 0;
      cursor: pointer;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      color: #333333;
      text-align: left;

      &:hover {
        font-family: Pretendard;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        background-color: ${surveytype === "student"
          ? "#D2D2F9"
          : surveytype === "teacher"
          ? "#E5FDA3"
          : surveytype === "edu"
          ? "#F5E1AE"
          : "#ffff"};
      }
    `}
  
  ${({ buttontype }) =>
    buttontype &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;

      /* Button */
      width: 100%;
      height: 44px;

      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;

      & > div:nth-child(1) {
        margin-left: 20px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 42px;
        /* identical to box height, or 210% */
        display: flex;
        align-items: center;
        letter-spacing: -0.32px;

        color: #333333;
      }

      & > div:nth-child(2) {
        margin-right: 10px;
      }
    `}
  ${({ contact }) =>
    contact &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      font-weight: 600;
      background: ${contact === "send" ? "#000000" : "#c5c5c5"};
      color: ${contact === "send" ? "#ffffff" : "#777777;"};
    `}
`;
