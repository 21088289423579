import styled from "styled-components";

// 모달 스타일링
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  width: 500px;
  height: 500px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const QRDiv = styled.div``;

export const ButtonDiv = styled.div`
  & > div:first-child {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #000000;
  }
  & > div:last-child {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
`;
