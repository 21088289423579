import React from "react";
import * as S from "./styles/AboutSurveySection.styled";
import {
  subImg1,
  subImg2,
  subImg3,
  subImg4,
  subImg5,
} from "../../styles/image/png";

export const SurveySubjectSection: React.FC = () => {
  const tagList = ["공통1", "공통2", "공통3", "공통4", "환경교육담당"];
  const titleList = [
    { first: "응답자", second: "배경정보" },
    { first: "환경/환경교육", second: "인식&관심도" },
    { first: "학교 환경교육 ", second: "학습경험" },
    { first: "환경교육의", second: "성과와 개선" },
    { first: "학교 환경교육", second: "운영 여건" },
  ];
  const imgList = [subImg1, subImg2, subImg3, subImg4, subImg5];

  const renderingCard = () => {
    const rows = [];
    for (let i = 0; i < 5; i++) {
      const { first, second } = titleList[i];
      const row = (
        <div className={"card"} key={`${i}_div`}>
          <div className={"tag"}>{tagList[i]}</div>
          <div className={"title"}>
            <div>{first}</div>
            <div>{second}</div>
          </div>
          <div className={"img"}>
            <img src={imgList[i]} alt={"img_list"} />
          </div>
        </div>
      );
      rows.push(row);
    }
    return rows;
  };
  return (
    <S.AboutSurveySection>
      <div>
        <S.AboutTitle>조사 항목</S.AboutTitle>
        <S.AboutContent>
          <S.AboutThird>{renderingCard()}</S.AboutThird>
        </S.AboutContent>
      </div>
    </S.AboutSurveySection>
  );
};
