import React, { useEffect, useState } from "react";
import * as S from "./styles/ContactForm.styled";
import { Input } from "../common/input/Input";
import Button from "../common/button/Button";
import { sendEmail } from "../../apis/email/email.api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const ContactForm: React.FC = () => {
  const userData = useSelector((state: RootState) => state.user);
  const [school, setSchool] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    setSchool(userData.userSchool ? userData.userSchool : "");
  }, []);

  //취소
  const reset = () => {
    setSchool("");
    setName("");
    setPhone("");
    setEmail("");
    setContent("");
  };

  //send
  const send = async () => {
    try {
      const sendData = {
        school,
        name,
        phone,
        email,
        content,
      };
      const validation = valid();
      if (validation) {
        const { success } = await sendEmail(sendData);
        if (success) {
          toast.success("메일 전송이 완료되었습니다.", {
            position: "top-right",
            autoClose: 3000, // 3초 후 자동으로 닫힘
          });
        } else {
          toast.error("메일 전송이 실패하였습니다.", {
            position: "top-right",
            autoClose: 3000, // 3초 후 자동으로 닫힘
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  //emailValidation
  const valid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 전화번호 유효성 검사 정규식 (010-1234-5678 형식 체크) -> 241008 해제
    //const phoneRegex = /^01[016789]-\d{3,4}-\d{4}$/;
    if (school.length < 3) {
      toast.error("학교명을 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    if (name.length < 2) {
      toast.error("담당자명을 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    if (content.length < 2) {
      toast.error("본문을 2글자 이상 입력해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    /*if (!phoneRegex.test(phone)) {
      toast.error("전화번호를 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }*/
    if (!emailRegex.test(email)) {
      toast.error("이메일을 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    return true;
  };

  return (
    <S.ContactFormSection>
      <S.Title>ContactUs</S.Title>
      <S.Form>
        <div className={"school"}>
          <Input
            inputdesigntype={"faq-form"}
            style={{ width: "100%" }}
            placeholder={"학교명"}
            value={school}
            onChange={(e) => setSchool(e.target.value)}
          />
        </div>
        <div className={"address"}>
          <div>
            <Input
              inputdesigntype={"faq-form"}
              style={{ width: "100%" }}
              placeholder={"담당자명"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <Input
              inputdesigntype={"faq-form"}
              style={{ width: "100%" }}
              placeholder={"연락처"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div>
            <Input
              inputdesigntype={"faq-form"}
              style={{ width: "100%" }}
              placeholder={"이메일"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className={"content"}>
          <textarea
            placeholder={"문의 내용"}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
        <div className={"buttons"}>
          <Button
            style={{ width: "490px", height: "60px" }}
            contact={"cancel"}
            onClick={reset}
          >
            취소
          </Button>
          <Button
            style={{ width: "598px", height: "60px" }}
            contact={"send"}
            onClick={send}
          >
            Send
          </Button>
        </div>
      </S.Form>
    </S.ContactFormSection>
  );
};
