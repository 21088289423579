import React, { useEffect } from "react";
import * as S from "./styles/MainView.styled";
import { Route, Routes, Navigate } from "react-router-dom";
import { Login } from "../../login/Login";
import { CurrentView } from "../current-view/CurrentView";
import { AboutView } from "../about-view/AboutView";
import { BoardView } from "../board-view/BoardView";
import { NoticeView } from "../board-view/NoticeView";
import { FAQView } from "../board-view/FAQView";
import { NoticeReadView } from "../board-view/NoticeReadView";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ProtectedRoute from "../../login/ProtectedRoute";
import { setUserData } from "../../../store/userSlice";

export const MainView = () => {
  const isLogin = useSelector((state: RootState) => state.user.isLogin); // 로그인 여부 가져오기

  const dispatch = useDispatch();

  useEffect(() => {
    // localStorage에서 사용자 데이터 불러오기
    const storedUserData = sessionStorage.getItem("userData");

    if (storedUserData) {
      const {
        _id,
        token,
        userId,
        userSchool,
        studentUrl,
        teacherUrl,
        ecoUrl,
        schoolType,
        isLogin,
        studentQr,
        teacherQr,
        eduQr,
        region,
      } = JSON.parse(storedUserData);

      // Redux에 로그인 정보 복원
      dispatch(
        setUserData({
          _id,
          token,
          userId,
          userSchool,
          studentUrl,
          teacherUrl,
          ecoUrl,
          schoolType,
          isLogin,
          studentQr,
          teacherQr,
          eduQr,
          region,
        })
      );
    }
  }, [dispatch]);
  return (
    <S.MainViewSection>
      <Routes>
        <Route
          path="/"
          element={
            isLogin ? (
              <Navigate to="/current" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/current"
          element={
            <ProtectedRoute>
              <CurrentView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/about"
          element={
            <ProtectedRoute>
              <AboutView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <BoardView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notice"
          element={
            <ProtectedRoute>
              <NoticeView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notice-view"
          element={
            <ProtectedRoute>
              <NoticeReadView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <FAQView />
            </ProtectedRoute>
          }
        />
      </Routes>

      <ToastContainer />
    </S.MainViewSection>
  );
};

/*// AuthChecker: 경로가 변경될 때마다 로그인 여부 확인
const AuthChecker: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin && location.pathname !== "/login") {
      // 로그인되지 않은 상태에서 /login이 아닌 경로로 진입 시 /login으로 리다이렉트
      navigate("/login");
    }
  }, [isLogin, location.pathname, navigate]);

  return <>{children}</>;
};*/
