import React from "react";
import * as S from "./styles/AboutView.styled";
import { titleSchool } from "../../../styles/image/svg";
import { SurveyPartSection } from "../../about/SurveyPartSection";
import { SurveySubjectSection } from "../../about/SurveySubjectSection";
import { AboutSurveySection } from "../../about/AboutSurveySection";

export const AboutView: React.FC = () => {
  return (
    <S.AboutViewSection>
      <S.ViewTitle>
        <div className={"title-img"}>
          <img src={titleSchool} alt={"학교"} />
        </div>
        <div className={"title-txt"}>조사 소개</div>
      </S.ViewTitle>
      <AboutSurveySection />
      <SurveyPartSection />
      <SurveySubjectSection />
    </S.AboutViewSection>
  );
};
