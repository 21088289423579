import styled from "styled-components";

export const AboutSurveySection = styled.section`
  width: 1400px;
  margin: auto;

  margin-top: 100px;

  &:last-child {
    width: 100%;
    padding: 100px 0px 100px 0px;

    background: #f5f5f5;

    & > div {
      width: 1400px;
      margin: auto;
    }
  }
`;
export const AboutTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  color: #000000;
`;
export const AboutContent = styled.div`
  margin-top: 20px;
`;
export const AboutFirst = styled.div`
  display: flex;
  gap: 20px;

  justify-content: space-between;
  & > div:nth-child(1) {
    width: 54%;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */
    letter-spacing: -0.4px;

    color: #000000;
  }
  & > div:nth-child(2) {
    width: 46%;
    text-align: right;
  }
`;
export const AboutSecond = styled.div`
  & > .header {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > div {
      width: 33.3%;
      text-align: center;
      height: 80px;

      font-family: Pretendard;
      font-size: 24px;
      font-weight: 500;
      line-height: 28.64px;
      letter-spacing: 1px;
      align-content: center;
    }
    & > div:nth-child(1) {
      background: #d2d2f9;
      border-right: 1px solid #d2d2d2;
    }
    & > div:nth-child(2) {
      background: #e5fda3;
      border-right: 1px solid #d2d2d2;
    }
    & > div:nth-child(3) {
      background: #f5e1ae;
    }
  }
  & > .body {
    border-bottom: 1px solid #000000;
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > div {
      width: 33.3%;
      font-family: Pretendard;
      font-size: 24px;
      font-weight: 500;
      line-height: 28.64px;
      letter-spacing: 1px;
      padding: 50px 0px 80px 40px;
      & > div {
        display: flex;
        & > div:first-child {
          width: 20px;
        }
      }
      & > div:not(.dot) {
        & > div:nth-child(2) {
          display: flex;
          margin-bottom: 10px;
          & > div:first-child {
            width: 120px;
          }
        }
      }
      & > div:first-child {
        margin-bottom: 18px;
      }
    }
    & > div:first-child {
      border-right: 1px solid #d2d2d2;
    }
    & > div:nth-child(2) {
      border-right: 1px solid #d2d2d2;
    }
  }
`;

export const AboutThird = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  & .card {
    position: relative;

    width: 231px;
    height: 309px;
    padding: 30px 0px 0px 30px;

    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
  }
  & .tag {
    width: 57.05px;
    height: 28px;

    background: #00945e;
    border-radius: 14px;

    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    /* or 162% */
    text-align: center;
    letter-spacing: -0.26px;

    color: #ffffff;
  }
  & .title {
    margin-top: 30px;
    text-align: left;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.4px;

    color: #111111;
  }
  & .img {
    text-align: right;
    position: absolute;
    height: fit-content;
    bottom: 0;
    right: 0;
  }

  & .card:last-child .tag {
    width: 100px;
    background: #f5e1ae;
    border-radius: 14px;

    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 162% */
    text-align: center;
    letter-spacing: -0.26px;

    color: #101010;
  }
`;
