import React, { useState } from "react";
import * as S from "./styles/NoticeTable.styled";
import { FaqData } from "../../data/board/faq-data";

export const FaqTable: React.FC = () => {
  const [openRow, setOpenRow] = useState<number | null>(null);

  /*const handleTitleClick = (seq: number) => {
    console.log(`Title clicked: ${seq}`);
    const faq = FaqData.filter((v) => v.seq === seq)[0];
    const row = (
      <tr key={faq.seq}>
        <td>{faq.content}</td>
      </tr>
    );
  };*/

  const handleTitleClick = (seq: number) => {
    // 클릭된 row의 seq가 현재 열려있다면 닫기, 아니면 해당 row 열기
    setOpenRow((prevSeq) => (prevSeq === seq ? null : seq));
  };
  const renderRow = () => {
    const rows: any[] = [];
    FaqData.sort((a, b) => {
      return b.seq - a.seq;
    }).forEach((faq, index) => {
      const isOpen = openRow === faq.seq; // 클릭된 row와 비교
      rows.push(
        <React.Fragment key={index}>
          <tr id={`row-${faq.seq}`}>
            <td>{faq.seq}</td>
            <td className="title" onClick={() => handleTitleClick(faq.seq)}>
              {faq.title}
            </td>
            <td>{faq.writer}</td>
            <td>{faq.date}</td>
          </tr>

          {/* 클릭된 row의 내용이 열렸을 때만 표시 */}
          {isOpen && (
            <tr>
              <td
                className={"faq-content-td"}
                colSpan={4}
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <div>{faq.content}</div>
                {faq?.secondLine ? <div>{faq?.secondLine}</div> : ""}
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
    return rows;
  };

  return (
    <S.NoticeTable>
      <S.Table>
        <colgroup>
          <col width={"7%"} />
          <col width={"60%"} />
          <col width={"15%"} />
          <col width={"20%"} />
        </colgroup>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
          </tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </S.Table>
    </S.NoticeTable>
  );
};
