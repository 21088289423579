import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  _id: string | null;
  token: string | null;
  userId: string | null;
  userSchool: string | null;
  studentUrl: string | null;
  teacherUrl: string | null;
  ecoUrl: string | null;
  schoolType: string | null;
  isLogin: boolean | null;
  studentQr: string | null;
  teacherQr: string | null;
  eduQr: string | null;
  region: string | null;
}

const initialState: UserState = {
  _id: null,
  token: null,
  userId: null,
  userSchool: null,
  studentUrl: null,
  teacherUrl: null,
  ecoUrl: null,
  schoolType: null,
  isLogin: false,
  studentQr: null,
  teacherQr: null,
  eduQr: null,
  region: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // 로그인 성공 시 사용자 정보를 저장하는 액션
    setUserData: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
    // 로그아웃 시 사용자 정보를 초기화하는 액션
    clearUserData: () => initialState,
  },
});

export const { setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;
