import { axiosInstance } from "../../libs/axios/axiosInstance";

const USER = "/user";
export const loginApi = async (loginData: {
  userId: string;
  userPw: string;
}) => {
  const url = `${USER}/login`;

  const { data } = await axiosInstance.post(url, loginData);
  if (data.result) return data;
};

export const changePwApi = async (changePwData: {
  currentPw: string;
  changePw: string;
}) => {
  const url = `${USER}/changePw`;

  const { data } = await axiosInstance.put(url, changePwData);
  return data;
};
