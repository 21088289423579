import styled from "styled-components";

export const ContactFormSection = styled.div`
  width: 1400px;
  margin: auto;
  display: flex;
  gap: 20px;

  margin-bottom: 100px;
`;
export const Title = styled.div`
  width: 28%;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  /* identical to box height */
  letter-spacing: 0.1px;

  color: #000000;
`;
export const Form = styled.div`
  width: 72%;
  & .address {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    & > div:first-child {
      width: 20%;
    }
    & > div:nth-child(2) {
      width: 30%;
    }
    & > div:last-child {
      width: 50%;
    }
  }

  & .content {
    width: 100%;
    & > textarea {
      width: 96.8%;
      resize: none;
      margin-top: 10px;
      border-radius: 8px;
      border: 1px solid #b8b8b8;
      height: 200px;
      padding: 15px;

      font-family: "Pretendard";
      font-style: normal;
      font-size: 18px;
      line-height: 16px;
      color: #262626;
    }
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
  }
`;
