import styled from "styled-components";

// 모달 스타일링
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  width: 300px;
  height: 310px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const StringDiv = styled.div`
  margin-bottom: 20px;
  & > div {
    display: flex;
    gap: 10px;
    & > div {
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: left;

      color: #000000;
    }
  }
`;
export const FormDiv = styled.div`
  & > div {
    margin: 10px 0px;
  }
`;
export const ButtonDiv = styled.div`
  margin-top: 30px;
`;
