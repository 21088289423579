import React, { useEffect, useState } from "react";
import * as S from "./styles/GraphSection.styled";
import { getCurrentData } from "../../apis/survey/survey.api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const GraphSection: React.FC = () => {
  const userData = useSelector((state: RootState) => state.user);

  const [totalData, setTotalData] = useState<any[]>([]);

  useEffect(() => {
    const getCurrent = async () => {
      const { ecoTeacher, student, teacher } = await getCurrentData(
        userData.userId ? userData.userId : ""
      );
      setTotalData([]);
      const totals: any[] = [];
      totals.push({
        type: "student",
        total: 46,
        complete: student
          ? student.reduce((acc: any, curr: any) => acc + curr.complete, 0)
          : 0,
      });
      totals.push({
        type: "teacher",
        total: 19,
        complete: teacher
          ? teacher.reduce((acc: any, curr: any) => acc + curr.complete, 0)
          : 0,
      });
      totals.push({
        type: "ecoTeacher",
        total: 1,
        complete: ecoTeacher
          ? ecoTeacher.reduce((acc: any, curr: any) => acc + curr.complete, 0)
          : 0,
      });

      setTotalData(totals);
    };

    getCurrent();
  }, []); // 의존성 배열을 빈 배열로 설정하여 컴포넌트가 처음 렌더링될 때만 실행

  const renderingBar = () => {
    const rows: any[] = [];
    totalData.forEach((item) => {
      const { type, complete } = item;
      const row = (
        <S.TypeDiv type={type} key={type}>
          <div className={"percent"}>
            <div>{complete}명 완료 </div>
            <div></div>
          </div>
          {/*<div className={"bar"}>
            <S.BarDiv percent={percent} className={"bar-color"}></S.BarDiv>
          </div>*/}
          <div className={"subject"}>
            {type === "student"
              ? "학생"
              : type === "teacher"
              ? "일반교사"
              : "환경교육 담당자"}{" "}
            참여 현황
          </div>
        </S.TypeDiv>
      );
      rows.push(row);
    });
    return rows;
  };

  return (
    <S.GraphSection>
      <S.SurveyTerm>
        <div>조사기간</div>
        <div>
          <div>2024. 10. 14. ~ 2024. 11. 08.</div>
        </div>
      </S.SurveyTerm>
      <S.SurveyGraph>
        {renderingBar()}
        {/* <S.TypeDiv type={"student"}>
          <div className={"percent"}>
            <div>62%</div>
            <div>40명 완료</div>
          </div>
          <div className={"bar"}>
            <S.BarDiv percent={10} className={"bar-color"}></S.BarDiv>
          </div>
          <div className={"subject"}>학생 참여 현황</div>
        </S.TypeDiv>
        <S.TypeDiv type={"teacher"}>
          <div className={"percent"}>
            <div>62%</div>
            <div>40명 완료</div>
          </div>
          <div className={"bar"}>
            <S.BarDiv percent={90} className={"bar-color"}></S.BarDiv>
          </div>
          <div className={"subject"}>일반교사 참여 현황</div>
        </S.TypeDiv>
        <S.TypeDiv type={"edu"}>
          <div className={"percent"}>
            <div>62%</div>
            <div>40명 완료</div>
          </div>
          <div className={"bar"}>
            <S.BarDiv percent={30} className={"bar-color"}></S.BarDiv>
          </div>
          <div className={"subject"}>환경교육 담당자 참여 현황</div>
        </S.TypeDiv>*/}
      </S.SurveyGraph>
    </S.GraphSection>
  );
};
