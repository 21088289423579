import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { Header } from "../components/header/Header";
import { MainView } from "../components/views/main-view/MainView";
import { Navigation } from "../components/header/Navigation";
import { Footer } from "../components/footer/Footer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUserData } from "../store/userSlice";

const AppDiv = styled(BrowserRouter)`
  display: flex;
  flex-direction: column;
`;
export const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // localStorage에서 사용자 데이터 불러오기
    const storedUserData = sessionStorage.getItem("userData");

    if (storedUserData) {
      const {
        _id,
        token,
        userId,
        userSchool,
        studentUrl,
        teacherUrl,
        ecoUrl,
        schoolType,
        isLogin,
        studentQr,
        teacherQr,
        eduQr,
        region,
      } = JSON.parse(storedUserData);

      // Redux에 로그인 정보 복원
      dispatch(
        setUserData({
          _id,
          token,
          userId,
          userSchool,
          studentUrl,
          teacherUrl,
          ecoUrl,
          schoolType,
          isLogin,
          studentQr,
          teacherQr,
          eduQr,
          region,
        })
      );
    }
  }, [dispatch]);
  return (
    <AppDiv>
      <nav>
        <Header></Header>
        <Navigation></Navigation>
      </nav>
      <MainView />
      <Footer />
    </AppDiv>
  );
};
