import styled from "styled-components";
export const NaviSection = styled.section`
  width: 100%;
  min-width: 1400px;
  height: 80px;
  display: flex;

  border-bottom: 1px solid #999999;
`;

export const NaviContainer = styled.div`
  min-width: 1400px;
  margin: 0 auto;
  align-self: center;
  display: flex;
  justify-content: space-between;
`;
export const NaviTitle = styled.div`
  width: 350px;
  height: 33px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.34px;

  color: #000000;
  cursor: pointer;
`;
export const NaviMenuContainer = styled.div`
  display: flex;
  gap: 60px;
  justify-content: space-between;
  align-items: flex-end;
`;
export const NaviMenu = styled.div`
  height: 24px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #000000;

  cursor: pointer;
  &:last-child {
    position: relative;
  }
`;

export const NaviSubMenu = styled.div`
  height: 24px;

  align-items: center;
  justify-content: center;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  text-align: center;

  color: #000000;

  cursor: pointer;
  &:last-child {
    position: relative;
  }
`;

export const SubMenuDiv = styled.div<{ show: string }>`
  display: ${({ show }) => (show === "true" ? "block" : "none")};

  box-sizing: border-box;
  position: absolute;
  width: 181px;
  height: 125px;
  top: 47px;
  left: -30px;

  background: #ffffff;
  border: 1px solid #999999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  & > div {
    width: 100%;
    height: 60px;

    font-family: "Pretendard";
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    align-content: center;

    color: #000000;
    cursor: pointer;
  }
  & > svg {
    /* Vector 40 */

    position: absolute;
    width: 129px;
    height: 0px;
    left: 23px;

    border: 1px solid #d9d9d9;
  }
`;
