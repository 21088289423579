import { ComponentProps, ReactElement, useState } from "react";
import * as S from "./styles/Input.styled";
import {
  lockLightIcon,
  userIcon,
  viewHideIcon,
} from "../../../styles/image/svg";

interface InputProps extends ComponentProps<"input"> {
  inputdesigntype?: string;
  inputButtonDisabled?: boolean;
}

export const Input = (props: InputProps): ReactElement => {
  const {
    placeholder,
    disabled,
    className,
    value,
    onChange,
    style,
    id,
    type,
    onKeyPress,
    inputdesigntype,
    inputButtonDisabled,
  } = props;
  const [inputPW, setInputPW] = useState(
    inputButtonDisabled ? true : type === "password"
  );

  const typeChange = () => {
    if (!inputButtonDisabled) setInputPW(!inputPW);
    if (inputButtonDisabled) setInputPW(true);
  };
  return (
    <S.InputDiv>
      <div className={"front"}>
        {inputdesigntype === "login-ID" ? (
          <img src={userIcon} alt={"user_icon"} />
        ) : inputdesigntype === "login-PW" || inputdesigntype === "modal-PW" ? (
          <img src={lockLightIcon} alt={"lock_icon"} />
        ) : (
          ""
        )}
      </div>
      <S.Input
        style={style}
        type={type === "password" ? (inputPW ? "password" : "text") : type}
        placeholder={placeholder}
        disabled={disabled}
        className={className}
        value={value}
        id={id}
        onChange={onChange}
        onKeyPress={onKeyPress}
        inputdesigntype={inputdesigntype}
      />

      {inputdesigntype === "login-PW" ? (
        <div className={"back"} onClick={typeChange}>
          <img src={viewHideIcon} alt={"view_icon"} />{" "}
        </div>
      ) : inputdesigntype === "modal-PW" ? (
        <div className={"back-modal"} onClick={typeChange}>
          <img src={viewHideIcon} alt={"hide_icon"} />{" "}
        </div>
      ) : (
        ""
      )}
    </S.InputDiv>
  );
};
