import styled, { css } from "styled-components";

interface LinkProps {
  type?: string;
}
export const LinkSection = styled.div`
  margin-top: 100px;
`;

export const SectionTitle = styled.div`
  display: flex;
  gap: 30px;

  align-items: baseline;
  & > div:nth-child(1) {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: flex-end;
    color: #000000;
  }
  & > div:nth-child(2) {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;

    color: #828282;
  }
`;

export const SectionDiv = styled.div`
  width: 100%;
  margin-top: 60px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const LinkDiv = styled.div<LinkProps>`
  box-sizing: border-box;
  width: 320px;
  height: 325px;

  border: 1px solid #c9c9c9;
  border-radius: 8px;
  padding: 13px 30px;
  ${({ type }) =>
    type &&
    css`
      background: ${type === "student"
        ? "#d2d2f9"
        : type === "teacher"
        ? "#e5fda3"
        : type === "edu"
        ? "#f5e1ae"
        : "#e6e6e6"};
    `}

  &>.subject {
    height: 29px;

    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: #333333;
  }

  & > .img {
    text-align: center;
    margin-top: 22px;
    height: 174px;
    overflow-y: hidden;
  }

  & > .drop-down {
    border-top: 1px solid #858585;
    padding-top: 20px;
    & > .place-holder {
      /* Item */

      height: 46px;

      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;
      display: flex;

      justify-content: space-between;
      cursor: pointer;

      & > div:nth-child(1) {
        margin-left: 20px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 42px;
        /* identical to box height, or 210% */
        display: flex;
        align-items: center;
        letter-spacing: -0.32px;

        color: #333333;
      }
      & > div:nth-child(2) {
        margin-right: 10px;
        align-self: center;
      }
    }
    & > .options {
      /* Frame 1000004430 */
      width: 256px;
      /* Auto layout */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0px;
      position: absolute;

      /* White/White */
      background: #ffffff;
      border: 1px solid #cccccc;
      /* Shadow 1 */
      box-shadow: 0px 1px 3px rgba(166, 175, 195, 0.4);
      border-radius: 6px;
    }
  }
`;

export const VerticalLine = styled.div``;
