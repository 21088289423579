import React from "react";
import * as S from "./styles/AboutSurveySection.styled";
import { aboutPhoto } from "../../styles/image/png";

export const AboutSurveySection: React.FC = () => {
  return (
    <S.AboutSurveySection>
      <S.AboutTitle>학교 환경교육 현황조사</S.AboutTitle>
      <S.AboutContent>
        <S.AboutFirst>
          <div>
            교육부와 한국환경보전원에서는 전국의 초·중·고등학생 및 선생님들을
            대상으로 학교 환경교육에 대한 인식 및 경험을 조사하고 있습니다.
            <br /> 이 연구는 한국교육개발원의 ‘학교 환경교육 현황 조사체계 구축
            방안 연구(Ⅲ)’ 결과로 개발한 ‘2023년 학교 환경교육 현황조사 도구‘를
            활용하여 학교에서 이루어진 환경교육과 관련된 학습 경험을 파악하고,
            이후 학교 환경교육이 더 발전할 수 있는 대안을 마련하는데 목적을 두고
            있습니다.
            <br /> 조사 결과는 우리나라 전체 학교 환경교육의 현황 뿐만 아니라,
            학교별로 환경교육을 발전 시켜나가는 데 매우 소중한 자료로 활용될
            것입니다.{" "}
          </div>
          <div>
            <img src={aboutPhoto} alt={"aboutPhoto"} />
          </div>
        </S.AboutFirst>
      </S.AboutContent>
    </S.AboutSurveySection>
  );
};
