import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isLogin = useSelector((state: RootState) => state.user.isLogin);

  if (!isLogin) {
    // 로그인이 되어있지 않으면 /login 페이지로 리다이렉트
    return <Navigate to="/login" replace />;
  }

  // 로그인이 되어있으면 자식 컴포넌트 렌더링
  return <>{children}</>;
};

export default ProtectedRoute;
