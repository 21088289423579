import React, { useEffect, useState } from "react";
import * as S from "./styles/BoardSection.styled";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { NoticeData } from "../../data/board/notice-data";
import { Input } from "../common/input/Input";
import Button from "../common/button/Button";
import { sendEmail } from "../../apis/email/email.api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Links = styled(Link)`
  text-decoration: none;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  /* or 288% */

  color: #333333;
  &:hover {
    text-decoration: underline;
  }
`;

const NaviLink = styled(NavLink)`
  display: flex;

  font-family: "Pretendard";
  text-decoration: none;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  align-self: baseline;
  cursor: pointer;
  & > div:first-child {
    align-content: center;
  }
`;

export const BoardSection: React.FC = () => {
  const userData = useSelector((state: RootState) => state.user);
  const [school, setSchool] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    setSchool(userData.userSchool ? userData.userSchool : "");
  });

  const renderRow = () => {
    const rows: any[] = [];
    NoticeData.sort((a, b) => {
      return b.seq - a.seq;
    }).forEach((notice, index) => {
      const row = (
        <tr key={index}>
          <td className={"title"}>
            <Links to={`/notice-view?seq=${notice.seq}`}>{notice.title}</Links>
          </td>
          <td>{notice.date}</td>
        </tr>
      );
      rows.push(row);
    });
    return rows;
  };

  //send
  const send = async () => {
    try {
      const sendData = {
        school,
        name,
        phone,
        email,
        content,
      };
      const validation = valid();
      if (validation) {
        const { success } = await sendEmail(sendData);
        if (success) {
          toast.success("메일 전송이 완료되었습니다.", {
            position: "top-right",
            autoClose: 3000, // 3초 후 자동으로 닫힘
          });
        } else {
          toast.error("메일 전송이 실패하였습니다.", {
            position: "top-right",
            autoClose: 3000, // 3초 후 자동으로 닫힘
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  //emailValidation
  const valid = () => {
    // 이메일 유효성 검사 정규식 (간단한 형식 체크)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 전화번호 유효성 검사 정규식 (010-1234-5678 형식 체크) -> 241008 해제
    //const phoneRegex = /^01[016789]-\d{3,4}-\d{4}$/;
    if (school.length < 3) {
      toast.error("학교명을 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    if (name.length < 2) {
      toast.error("담당자명을 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    if (content.length < 2) {
      toast.error("본문을 2글자 이상 입력해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    /*if (!phoneRegex.test(phone)) {
      toast.error("전화번호를 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }*/
    if (!emailRegex.test(email)) {
      toast.error("이메일을 확인해주세요", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
      return false;
    }
    return true;
  };
  return (
    <S.BoardSection>
      <S.NoticeDiv>
        <div className={"title"}>
          <div>공지사항</div>
          <div>
            <NaviLink to="/notice">
              <div>더보기</div>
              <div>
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_12_2386)">
                    <path
                      d="M11.4533 22.6415L17.56 16.3772L11.4533 10.1129L13.3333 8.1886L21.3333 16.3772L13.3333 24.5658L11.4533 22.6415Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_12_2386">
                      <rect width="32" height="32.7544" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </NaviLink>
          </div>
        </div>
        <div className={"table"}>
          <table>
            <colgroup>
              <col width={"80%"} />
              <col width={"20%"} />
            </colgroup>
            <tbody>{renderRow()}</tbody>
          </table>
        </div>
      </S.NoticeDiv>
      <S.ContactDiv>
        <div className={"title"}>Contact Us</div>
        <div className={"form"}>
          <S.Form>
            <div className={"school"}>
              <Input
                inputdesigntype={"faq-form"}
                style={{ width: "100%" }}
                placeholder={"학교명"}
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
            </div>
            <div className={"address"}>
              <div>
                <Input
                  inputdesigntype={"faq-form"}
                  style={{ width: "100%" }}
                  placeholder={"담당자명"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <Input
                  inputdesigntype={"faq-form"}
                  style={{ width: "100%" }}
                  placeholder={"연락처"}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div>
                <Input
                  inputdesigntype={"faq-form"}
                  style={{ width: "100%" }}
                  placeholder={"이메일"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={"content"}>
              <textarea
                placeholder={"문의 내용"}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
            <div className={"buttons"}>
              <Button
                style={{ width: "100%", height: "60px" }}
                onClick={send}
                contact={"send"}
              >
                Send
              </Button>
            </div>
          </S.Form>
        </div>
      </S.ContactDiv>
    </S.BoardSection>
  );
};
