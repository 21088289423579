import { axiosInstance } from "../../libs/axios/axiosInstance";

interface EmailData {
  school: string;
  name: string;
  phone: string;
  email: string;
  content: string;
}

const URL = "/mail";
export const sendEmail = async (mailData: EmailData) => {
  const url = `${URL}/send`;

  const { data } = await axiosInstance.post(url, mailData);
  if (data.success) return data;
};
