import styled from "styled-components";

export const FooterSection = styled.section`
  width: 100%;
  min-width: 1400px;
  height: 178px;
  min-height: 178px;
  background-color: #111111;
`;

export const FooterString = styled.div`
  margin: auto;
  margin-top: 50px;
  max-width: 1400px;

  & > div {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;

    color: rgba(255, 255, 255, 0.8);

    & > div:nth-child(2) {
      margin-left: 20px;
    }
  }
`;
