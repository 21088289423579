import { axiosInstance } from "../../libs/axios/axiosInstance";

const URL = "/survey";
/**
 * 우리학교 조사 참여현황 데이터 가져오기
 * @param userData
 */
export const getCurrentData = async (schoolCode: string) => {
  const url = `${URL}/current?schoolCode=${schoolCode}`;
  const { data } = await axiosInstance.get(url);
  return data;
};
