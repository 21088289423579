import styled, { css } from "styled-components";

export const InputDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  .front {
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
  }
  .back {
    position: absolute;
    margin-left: 440px;
    margin-top: 10px;
    cursor: pointer;
  }
  .back-modal {
    position: absolute;
    margin-left: 266px;
    margin-top: 10px;
    cursor: pointer;
  }
`;

export const Input = styled.input<{ inputdesigntype?: string | undefined }>`
  box-sizing: border-box;
  padding: 5px;
  padding-right: 40px;
  /* Input */
  background: #ffffff;
  font-size: 13.3px;
  line-height: 16px;

  font-family: "Pretendard";

  ${({ inputdesigntype }) =>
    inputdesigntype &&
    css`
      padding-left: ${inputdesigntype === "faq-form" ? "10px" : "40px"};
      border-radius: ${inputdesigntype === "faq-form" ? "8px" : ""};
      border: ${inputdesigntype === "faq-form"
        ? "1px solid #b8b8b8"
        : "1px solid #ddd"};
      font-size: ${inputdesigntype === "faq-form" ? "18px" : "13.3px"};
      color: ${inputdesigntype === "faq-form" ? "#333333" : "#262626"};

      height: ${inputdesigntype === "faq-form" ? "50px" : "40px"};
    `}
`;
