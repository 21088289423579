import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
// @ts-ignore
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "school-eco",
  version: 1,
  storage,
};

const reducer = combineReducers({ user: userReducer });

const persistedReducer = persistReducer(persistConfig, reducer);
// Redux store 생성
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // 경고를 무시하고 싶다면 이 부분 추가
    }),
});
// RootState와 AppDispatch 타입 추출 (타입스크립트용)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
