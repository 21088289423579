import styled from "styled-components";

export const BoardTableSection = styled.div`
  width: 1400px;
  margin: auto;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & th,
  & td {
    padding: 20px 0px;
  }
  & th {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    align-items: center;
    color: #000000;
  }

  /**border**/
  & > thead > tr:first-child {
    border-top: 1px solid #000000;
  }
  & > thead > tr:last-child,
  & > tbody > tr:last-child,
  & > tbody > tr > th:first-child {
    border-bottom: 1px solid #000000;
  }
  & > thead > tr:first-child > th:first-child,
  & > tbody > tr > th {
    letter-spacing: 0.8rem;
    background: #eeeeee;
    border-right: 1px solid #000000;
  }
  & > thead > tr:first-child > th:nth-child(2) {
    background: #d2d2f999;
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #000000;
  }
  & > thead > tr:first-child > th:last-child {
    background: #e5fda399;
    border-bottom: 1px solid #d2d2d2;
  }
  & > thead > tr:last-child > th:nth-child(1) {
    background: #d2d2f999;
    border-right: 1px solid #d2d2d2;
  }
  & > thead > tr:last-child > th:nth-child(2) {
    background: #d2d2f999;
    border-right: 1px solid #000000;
  }
  & > thead > tr:first-child > th:last-child {
    background: #e5fda399;
    border-bottom: 1px solid #d2d2d2;
  }
  & > thead > tr:last-child > th:nth-child(3) {
    background: #e5fda399;
    border-right: 1px solid #d2d2d2;
  }
  & > thead > tr:last-child > th:nth-child(4) {
    background: #e5fda399;
    border-right: 1px solid #d2d2d2;
  }
  & > thead > tr:last-child > th:nth-child(5) {
    background: #f5e1ae;
  }
  & > tbody > tr:first-child td {
    border-bottom: 1px solid #000000;
  }
  & > tbody > tr:first-child td:nth-child(2),
  & > tbody > tr:first-child td:nth-child(4),
  & > tbody > tr:first-child td:nth-child(5) {
    border-right: 1px solid #d2d2d2;
  }
  & > tbody > tr:first-child td:nth-child(3) {
    border-right: 1px solid #000000;
  }

  & > tbody > tr:last-child td:nth-child(2),
  & > tbody > tr:last-child td:nth-child(4),
  & > tbody > tr:last-child td:nth-child(5) {
    border-right: 1px solid #d2d2d2;
  }
  & > tbody > tr:last-child td:nth-child(3) {
    border-right: 1px solid #000000;
  }
`;

export const TD = styled.td<{ complete: string }>`
  font-family: "Pretendard";
  font-style: normal;
  font-size: 24px;
  line-height: 44px;
  text-align: center;

  font-weight: ${({ complete }) => (complete === "true" ? "700" : "400")};

  color: ${({ complete }) => (complete === "true" ? "#00945E" : "#000000")};
`;

export const InfoString = styled.div`
  margin-top: 30px;
  padding-bottom: 100px;
  & > div {
    display: flex;
    gap: 15px;
    margin-bottom: 7px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    /* or 145% */

    color: #6f6f6f;
  }
  & > div:first-child {
    margin-bottom: 15px;
  }
  & > div > div {
    display: flex;
    gap: 10px;
  }
`;
