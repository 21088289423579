import React from "react";
import * as S from "./styles/ModalComponent.styled";
import Button from "../common/button/Button";
import { axiosInstance } from "../../libs/axios/axiosInstance";

// 모달 컴포넌트
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
  type?: string;
  qrUrl?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  id,
  type,
  qrUrl,
}) => {
  if (!isOpen) return null; // 모달이 닫힌 상태에서는 렌더링되지 않음
  // 모달 창 외부 클릭 시 모달을 닫는 함수
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose(); // 오버레이를 클릭했을 때만 모달을 닫음
    }
  };

  const download = async () => {
    try {
      const fileUrl = qrUrl ? qrUrl : "";
      const fileTitle = `설문조사 참여링크 QR_${
        type === "student"
          ? "학생용"
          : type === "teacher"
          ? "교사용"
          : "환경교육담당자용"
      }`;

      const backendUrl = `/survey/downloadQR?fileUrl=${encodeURIComponent(
        fileUrl
      )}`;
      const response = await axiosInstance.get(backendUrl, {
        responseType: "blob", // 서버에서 파일 데이터를 blob 형식으로 받기
      });

      // Blob 처리
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      }); // Blob으로 변환
      const url = URL.createObjectURL(blob); // Blob 데이터를 다운로드 가능한 URL로 변환

      // 다운로드를 위한 앵커 태그 생성
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileTitle}.png`; // 다운로드될 파일 이름 지정
      document.body.appendChild(a);
      a.click(); // 클릭 이벤트 트리거로 다운로드 실행

      // 메모리 해제
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <S.ModalOverlay onClick={handleOverlayClick}>
      <S.ModalContent>
        <S.QRDiv>
          <img
            id={"qr-img"}
            src={qrUrl ? qrUrl : ""}
            style={{ maxWidth: "100%" }}
            alt={""}
          />
        </S.QRDiv>
        <S.ButtonDiv>
          <div>
            [
            {type === "student"
              ? "학생용"
              : type === "teacher"
              ? "일반교사용"
              : "환경교육 담당자용"}
            ]
          </div>
          <div>
            <Button
              style={{ width: "50%", height: "40px" }}
              contact={"send"}
              onClick={download}
            >
              다운로드
            </Button>
            <Button
              style={{ width: "50%", height: "40px" }}
              contact={"send"}
              onClick={onClose}
            >
              닫기
            </Button>
          </div>
        </S.ButtonDiv>
      </S.ModalContent>
    </S.ModalOverlay>
  );
};
