import React, { useState } from "react";
import * as S from "./styles/PasswordModal.styled";
import { Input } from "../common/input/Input";
import { changePwApi } from "../../apis/user/user.api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import Button from "../common/button/Button";

// 모달 컴포넌트
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

export const PasswordModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  id,
}) => {
  const userData = useSelector((state: RootState) => state.user);
  const [currentPw, setcurrentPw] = useState("");
  const [changePw, setchangePw] = useState("");
  const [changePwChk, setchangePwChk] = useState("");
  if (!isOpen) {
    return null;
  } // 모달이 닫힌 상태에서는 렌더링되지 않음

  // 모달 창 외부 클릭 시 모달을 닫는 함수
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setcurrentPw("");
      setchangePw("");
      setchangePwChk("");
      onClose(); // 오버레이를 클릭했을 때만 모달을 닫음
    }
  };

  const validPw = () => {
    if (changePw.length < 4) {
      return false;
    }
    if (currentPw.length < 1) {
      return false;
    }
    /*const hasNumber = /[0-9]/.test(changePw);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(changePw);
    const hasLetter = /[a-zA-Z]/.test(changePw);*/
    const same = changePw === changePwChk;

    //조건을 모두 만족하면 유효한 비밀번호
    //return hasNumber && hasSpecialChar && hasLetter && same;
    return same;
  };

  const submitPw = async () => {
    const validation = validPw();
    if (validation) {
      const submitData = {
        currentPw,
        changePw,
        userId: userData.userId,
      };
      const { result, message } = await changePwApi(submitData);
      if (result) {
        toast.success(message, {
          position: "top-right",
          autoClose: 3000, // 3초 후 자동으로 닫힘
        });

        setcurrentPw("");
        setchangePw("");
        setchangePwChk("");
        onClose();
      } else {
        toast.error(message, {
          position: "top-right",
          autoClose: 3000, // 3초 후 자동으로 닫힘
        });
      }
    } else {
      toast.error("입력을 확인해주세요.", {
        position: "top-right",
        autoClose: 3000, // 3초 후 자동으로 닫힘
      });
    }
  };
  return (
    <S.ModalOverlay onClick={handleOverlayClick}>
      <S.ModalContent>
        <S.StringDiv>
          <div>
            <div>-</div>
            <div>비밀번호는 최소 4자리 이상</div>
          </div>
          <div>
            <div>-</div>
            <div>숫자, 특수문자, 글자 모두 조합 제한없이 입력가능</div>
          </div>
        </S.StringDiv>
        <S.FormDiv>
          <div>
            <Input
              style={{ width: "100%" }}
              type={"password"}
              inputdesigntype={"modal-PW"}
              inputButtonDisabled={currentPw === ""}
              onChange={(e) => setcurrentPw(e.target.value)}
              value={currentPw}
              placeholder={"현재비밀번호"}
            />
          </div>
          <div>
            <Input
              inputdesigntype={"modal-PW"}
              style={{ width: "100%" }}
              placeholder={"변경할 비밀번호"}
              type={"password"}
              value={changePw}
              onChange={(e) => setchangePw(e.target.value)}
            />
          </div>
          <div>
            <Input
              inputdesigntype={"modal-PW"}
              style={{ width: "100%" }}
              placeholder={"변경할 비밀번호 확인"}
              type={"password"}
              value={changePwChk}
              onChange={(e) => setchangePwChk(e.target.value)}
            />
          </div>
        </S.FormDiv>
        <S.ButtonDiv>
          {validPw() ? (
            <Button
              onClick={submitPw}
              style={{ width: "100%" }}
              login={"true"}
              disabled={false}
            >
              비밀번호 변경
            </Button>
          ) : (
            <Button
              onClick={submitPw}
              style={{ width: "100%" }}
              login={"true"}
              disabled={true}
            >
              비밀번호 변경
            </Button>
          )}
        </S.ButtonDiv>
      </S.ModalContent>
    </S.ModalOverlay>
  );
};
