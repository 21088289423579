import styled from "styled-components";

export const HeaderSection = styled.section`
  background-color: #e9e9e9;
  width: 100%;
  min-width: 1400px;
  height: 70px;
  display: flex;
`;

export const HeaderContainer = styled.div`
  min-width: 1400px;
  margin: 0 auto;
  align-self: center;
  display: flex;
  justify-content: space-between;
`;
export const HeaderLogoSection = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
export const HeaderAccountInfoSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  & > svg {
    /* Line 145 */

    width: 18px;
    height: 0px;

    border: 1px solid #555555;
    transform: rotate(90deg);

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
export const Logo = styled.div`
  align-self: center;
`;
export const Info = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  & > .school-info {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    display: flex;
    align-items: flex-end;

    color: #000000;
  }
`;
export const InfoIcon = styled.div`
  width: 24px;
  height: 24px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const Account = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  & > .school-info {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    display: flex;
    align-items: flex-end;

    color: #000000;
  }
`;
export const AccountIcon = styled.div`
  width: 24px;
  height: 24px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const PasswordChange = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;

  color: #000000;
  cursor: pointer;
`;
